.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

.dhsv_scrollup {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-align: center;
  transform: translateX(calc(100% + 2rem));
  transition: transform .5s ease;
}

.dhsv_scrollup button {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: #09332c;
  color: #fff;
  transition: box-shadow .2s ease-in;
}

.dhsv_scrollup button i {
  line-height: 3rem;
  font-size: 1.25rem;
}

.dhsv_scrollup button:hover {
  box-shadow: 0 1px 4px 2px rgba(72,72,72,.25);
}

.dhsv_scrollup.fade-in {
  transform: translateX(0);
}

@media (max-width: 991.98px) {
  .dhsv_scrollup {
    bottom: 1rem;
    right: 1rem;
    transform: translateX(calc(100% + 1rem));
  }

  .dhsv_scrollup button {
    width: 2.5rem;
    height: 2.5rem;
  }

  .dhsv_scrollup button i {
    line-height: 2.5rem;
    font-size: 1.125rem;
  }
}

.single-office-space .dhsv_scrollup,
.single-coworking-space .dhsv_scrollup,
.single-business-center .dhsv_scrollup {
  bottom: 4rem;
}

@media (max-width: 1199.98px) {
  .single-office-space .dhsv_scrollup,
  .single-coworking-space .dhsv_scrollup,
  .single-business-center .dhsv_scrollup {
    bottom: 2rem;
  }
}

@media (max-width: 991.98px) {
  .single-office-space .dhsv_scrollup,
  .single-coworking-space .dhsv_scrollup,
  .single-business-center .dhsv_scrollup {
    bottom: 1rem;
  }
}

