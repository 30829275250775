@import './../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.dhsv_scrollup {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-align: center;
  transform: translateX(calc(100% + #{$space-sm}));
  transition: transform 0.5s ease;

  button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: $primary-light;
    color: white;
    transition: box-shadow 0.2s ease-in;

    i {
      line-height: 3rem;
      font-size: $font-size-h3;
    }

    &:hover {
      box-shadow: 0 1px 4px 2px rgba($grey, 0.25);
    }
  }

  &.fade-in {
    transform: translateX(0);
  }

  @include media-breakpoint-down(md) {
    bottom: $space-xs;
    right: $space-xs;
    transform: translateX(calc(100% + #{$space-xs}));

    button {
      width: 2.5rem;
      height: 2.5rem;

      i {
        line-height: 2.5rem;
        font-size: $font-size-base;
      }
    }
  }
}

.single-office-space,
.single-coworking-space,
.single-business-center {
  .dhsv_scrollup {
    bottom: $space-md;
  }

  @include media-breakpoint-down(lg) {
    .dhsv_scrollup {
      bottom: $space-sm;
    }
  }

  @include media-breakpoint-down(md) {
    .dhsv_scrollup {
      bottom: $space-xs;
    }
  }
}
